import { gql } from 'apollo-angular';
import { GAME_DAY_INFO_FRAGMENT } from 'src/app/models/graphql/game-day-info.query';

const BB_FRAGMENT = gql`
  fragment Bb on Bb {
    __typename
    id
    name
    weight
    price
    public
    favorite
  }
`;

export const GET_GAME_DAYS = gql`
  query (
    $filter: [GameDayFilter!]!
    $sort: [GameDaySort!]!
    $skip: Int
    $take: Int
  ) {
    gameDays(filter: $filter, skip: $skip, sort: $sort, take: $take) {
      totalCount
      count
      nodes {
        id
        date
        comment
        active
        public
        locked
      }
    }
  }
`;

export const GET_ALL_GAME_DAYS = gql`
  query {
    gameDays(take: 99999) {
      nodes {
        id
        date
        comment
        active
        public
        locked
      }
    }
  }
`;

export const GET_ACTIVE_GAME_DAY = gql`
  query {
    gameDays(filter: { active: { equal: true } }) {
      nodes {
        id
        date
        comment
        active
        public
        locked
      }
    }
  }
`;

export const GET_ACTIVE_GAME_DAY_CHECKIN = gql`
  query (
    $gameDayFilter: [GameDayFilter!]!
    $filter: [GameDayCheckInFilter!]!
    $sort: [GameDayCheckInSort!]!
    $skip: Int
    $take: Int
  ) {
    gameDays(filter: $gameDayFilter) {
      nodes {
        id
        date
        active
        info {
          ...GameDayInfo
        }
        checkIn(filter: $filter, skip: $skip, sort: $sort, take: $take) {
          count
          totalCount
          nodes {
            id
            comment
            comment_CheckIn
            comment_Shop
            comment_Chrono
            attendance
            parentalPermissionSigned
            hasRentals
            hasViolations
            bbs {
              ...Bb
            }
            issuedBbs {
              ...Bb
            }
            gameSquad {
              id
              name
              colorHex
            }
            newTagStatus
            issuedNewTag
            newTagId {
              id
              tagId
              active
            }
            player {
              id
              fileId
              callSign
              nonLiabilityWaverSigned
              colorHex
              isAdult
              hasViolations
              team {
                name
                abbreviation
              }
              aggregatedStats {
                level
              }
            }
          }
        }
      }
    }
  }

  ${GAME_DAY_INFO_FRAGMENT}

  ${BB_FRAGMENT}
`;

export const GET_ACTIVE_GAME_DAY_RENTAL = gql`
  query (
    $gameDayFilter: [GameDayFilter!]!
    $filter: [GameDayPlayerRentalFilter!]!
    $sort: [GameDayPlayerRentalSort!]!
    $skip: Int
    $take: Int
  ) {
    gameDays(filter: $gameDayFilter) {
      nodes {
        id
        date
        active
        info {
          ...GameDayInfo
        }
        rental(filter: $filter, skip: $skip, sort: $sort, take: $take) {
          count
          totalCount
          nodes {
            id
            comment
            comment_CheckIn
            comment_Shop
            comment_Chrono
            attendance
            bbs {
              ...Bb
            }
            issuedBbs {
              ...Bb
            }
            gameSquad {
              id
              name
              colorHex
            }
            hasViolations
            player {
              id
              fileId
              callSign
              isAdult
              colorHex
              hasViolations
              team {
                name
                abbreviation
              }
              aggregatedStats {
                level
              }
            }
            issuedItems {
              id
              name
            }
            returnedItems {
              id
              name
            }
            rentalItems {
              id
              name
            }
            notPaidRentalItems {
              id
              name
            }
            rentalPackages {
              id
              name
              items {
                id
                name
              }
            }
          }
        }
      }
    }
  }

  ${GAME_DAY_INFO_FRAGMENT}
  ${BB_FRAGMENT}
`;

export const GET_ACTIVE_GAME_DAY_CHRONI = gql`
  query (
    $gameDayFilter: [GameDayFilter!]!
    $filter: [GameDayPlayerChronoFilter!]!
    $sort: [GameDayPlayerChronoSort!]!
    $skip: Int
    $take: Int
  ) {
    gameDays(filter: $gameDayFilter) {
      nodes {
        id
        date
        active
        info {
          ...GameDayInfo
        }
        chrono(filter: $filter, skip: $skip, sort: $sort, take: $take) {
          count
          totalCount
          nodes {
            id
            comment
            comment_CheckIn
            comment_Shop
            comment_Chrono
            attendance
            gameSquad {
              id
              name
              colorHex
            }
            hasViolations
            player {
              id
              fileId
              callSign
              isAdult
              colorHex
              hasViolations
              team {
                name
                abbreviation
              }
              aggregatedStats {
                level
              }
            }
            rentalItems {
              id
              name
              weaponType
            }
            rentalPackages {
              id
              name
              items {
                id
                name
                weaponType
              }
            }
            weapons {
              id
              name
              energy
              weaponType
              propulsionType
            }
          }
        }
      }
    }
  }

  ${GAME_DAY_INFO_FRAGMENT}
`;

export const GET_PLAYERS_FOR_GAME_DAY = gql`
  query (
    $filter: [GameDayFilter!]!
    $playerFilter: [GameDayPlayerFilter!]!
    $sort: [GameDayPlayerSort!]!
    $skip: Int
    $take: Int
  ) {
    gameDays(filter: $filter) {
      nodes {
        id
        date
        active
        public
        locked
        players(filter: $playerFilter, skip: $skip, sort: $sort, take: $take) {
          totalCount
          count
          nodes {
            id
            comment
            comment_CheckIn
            comment_Shop
            comment_Chrono
            attendanceState
            bbs {
              ...Bb
            }
            issuedBbs {
              ...Bb
            }
            rentalPackages {
              id
              name
              amountAvailable
              items {
                id
                name
                amountAvailable
              }
            }
            rentalItems {
              id
              name
              amountAvailable
            }
            notPaidRentalItems {
              id
              name
            }
            newTagStatus
            issuedNewTag
            player {
              id
              callSign
              firstName
              lastName
              mail
              nonLiabilityWaverSigned
              colorHex
              isAdult
              hasViolations
              team {
                id
                name
                abbreviation
              }
              aggregatedStats {
                level
              }
            }
            gameSquad {
              id
              name
              colorHex
            }
          }
        }
      }
    }
  }

  ${BB_FRAGMENT}
`;

export const GET_SQUADS_FOR_GAME_DAY = gql`
  query {
    squads(sort: [{ id: ASC }]) {
      nodes {
        id
        name
        colorHex
      }
    }
  }
`;

export const GET_PLAYERS_AND_SQUADS_FOR_GAME_DAY = gql`
  query (
    $filter: [GameDayFilter!]!
    $playerFilter: [GameDayPlayerFilter!]!
    $sort: [GameDayPlayerSort!]!
    $skip: Int
    $take: Int
  ) {
    squads(sort: [{ id: ASC }]) {
      nodes {
        id
        name
        colorHex
      }
    }
    gameDays(filter: $filter) {
      nodes {
        id
        date
        active
        public
        locked
        players(filter: $playerFilter, skip: $skip, sort: $sort, take: $take) {
          totalCount
          count
          nodes {
            id
            comment
            comment_CheckIn
            comment_Shop
            comment_Chrono
            attendanceState
            bbs {
              ...Bb
            }
            issuedBbs {
              ...Bb
            }
            rentalPackages {
              id
              name
              amountAvailable
              items {
                id
                name
                amountAvailable
              }
            }
            rentalItems {
              id
              name
              amountAvailable
            }
            notPaidRentalItems {
              id
              name
            }
            newTagStatus
            issuedNewTag
            player {
              id
              callSign
              nonLiabilityWaverSigned
              colorHex
              isAdult
              hasViolations
              team {
                id
                name
                abbreviation
              }
              aggregatedStats {
                level
              }
            }
            gameSquad {
              id
              name
              colorHex
            }
          }
        }
      }
    }
  }

  ${BB_FRAGMENT}
`;

export const ADD_GAME_DAYS = gql`
  mutation ($input: GameDayCreateInput!) {
    gameDay {
      create(input: $input) {
        id
        date
        comment
        active
        public
        locked
      }
    }
  }
`;

export const EDIT_GAME_DAYS = gql`
  mutation ($id: Int!, $input: GameDayEditInput!) {
    gameDay {
      update(id: $id, input: $input) {
        id
        date
        comment
        active
        public
        locked
      }
    }
  }
`;

export const DELETE_GAME_DAYS = gql`
  mutation ($ids: [Int!]!) {
    gameDay {
      delete(ids: $ids)
    }
  }
`;

export const ADD_GAME_DAY_PLAYER = gql`
  mutation ($gameDayId: Int, $input: GameDayPlayerCreateInput!) {
    gameDay {
      addPlayer(gameDayId: $gameDayId, input: $input) {
        id
      }
    }
  }
`;

export const EDIT_GAME_DAY_PLAYER = gql`
  mutation ($id: Int!, $input: GameDayPlayerEditInput) {
    gameDay {
      updatePlayer(id: $id, input: $input) {
        id
        bbs {
          ...Bb
        }
        rentalPackages {
          id
          name
          amountAvailable
          items {
            id
            name
            amountAvailable
          }
        }
        rentalItems {
          id
          name
          amountAvailable
        }
        player {
          id
          callSign
          colorHex
          team {
            id
            name
          }
        }
      }
    }
  }

  ${BB_FRAGMENT}
`;

export const CHECKIN_MUTATION = gql`
  mutation ($gameDayPlayerId: Int!, $checkIn: CheckInEdit!) {
    gameDay {
      checkIn(gameDayPlayerId: $gameDayPlayerId, checkIn: $checkIn)
    }
  }
`;

export const CHRONI_MUTATION = gql`
  mutation ($gameDayPlayerId: Int!, $chrono: ChronoEdit!) {
    gameDay {
      chrono(gameDayPlayerId: $gameDayPlayerId, chrono: $chrono)
    }
  }
`;

export const SHOP_MUTATION = gql`
  mutation ($gameDayPlayerId: Int!, $shop: ShopEdit!) {
    gameDay {
      shop(gameDayPlayerId: $gameDayPlayerId, shop: $shop)
    }
  }
`;

export const DELETE_GAME_DAY_PLAYER = gql`
  mutation ($ids: [Int!]!) {
    gameDay {
      deletePlayer(ids: $ids)
    }
  }
`;

export const ADD_GAMEDAY_PLAYER_WEAPON = gql`
  mutation ($id: Int!, $input: GameDayPlayerWeaponCreateInput!) {
    gameDay {
      addPlayerWeapon(gameDayPlayerId: $id, input: $input) {
        id
      }
    }
  }
`;

export const UPDATE_GAMEDAY_PLAYER_WEAPON = gql`
  mutation ($id: Int!, $input: GameDayPlayerWeaponEditInput!) {
    gameDay {
      updatePlayerWeapon(id: $id, input: $input) {
        id
      }
    }
  }
`;

export const DELETE_GAMEDAY_PLAYER_WEAPON = gql`
  mutation ($id: [Int!]!) {
    gameDay {
      deletePlayerWeapon(ids: $id)
    }
  }
`;
