import { gql } from 'apollo-angular';

export const EDITOR_FRAGMENT = gql`
  fragment EDITOR on Editor {
    __typename
    ... on User {
      username
    }
    ... on ApiKey {
      client
    }
  }
`;
