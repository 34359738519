import { Apollo, gql } from 'apollo-angular';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { Injectable } from '@angular/core';
import {
  AddNode,
  ComponentDataService,
  DeleteNode,
  EditNode,
} from 'src/app/modules/shared/base/component.data.service';
import { GameDay, GameDayType } from 'src/app/models/entities/game-day';
import {
  ADD_GAME_DAYS,
  DELETE_GAME_DAYS,
  EDIT_GAME_DAYS,
  GET_GAME_DAYS,
} from 'src/app/models/graphql/game-days.query';
import { catchError } from 'rxjs/operators';
import { format } from 'date-fns';
import { TableService } from 'src/app/modules/shared/components/table/table.service';
import { QueryResult } from 'src/app/models/entities/query-result';
import { Filter } from 'src/app/models/graphql/filter/filter.model';
import { Sort } from 'src/app/models/graphql/filter/sort.model';
import { map, Observable, of } from 'rxjs';
import { WithHistory } from 'src/app/modules/shared/components/slideIn/history/history.entry';
import { EDITOR_FRAGMENT } from 'src/app/models/graphql/editor.query';
import {
  GraphQlResponse,
  MutlipartService,
} from 'src/app/services/multipart/multipart.service';
import { ToastService } from 'src/app/services/toast/toast.service';

@Injectable({
  providedIn: 'root',
})
export class GameDaysDataService
  extends ComponentDataService
  implements AddNode<GameDay>, EditNode<GameDay>, DeleteNode<GameDay>
{
  constructor(
    private spinner: SpinnerService,
    private apollo: Apollo,
    private table: TableService,
    private multipart: MutlipartService,
    private toastService: ToastService
  ) {
    super();
  }

  load() {
    return this.apollo
      .query<{
        gameDays: QueryResult<GameDay>;
      }>({
        query: GET_GAME_DAYS,
        variables: {
          filter: this.table.filter$.value,
          sort: this.table.sort$.value,
          skip: this.table.skip$.value,
          take: this.table.take$.value,
        },
      })
      .pipe(
        catchError(err => {
          this.spinner.hide();
          throw err;
        })
      );
  }

  customLoad(config: {
    filter?: Filter[];
    sort?: Sort[];
    skip?: number;
    take?: number;
  }) {
    return this.apollo
      .query<{
        gameDays: QueryResult<GameDay>;
      }>({
        query: GET_GAME_DAYS,
        variables: {
          filter: config.filter ?? [],
          sort: config.sort ?? [],
          skip: config.skip,
          take: config.take,
        },
      })
      .pipe(
        catchError(err => {
          this.spinner.hide();
          throw err;
        })
      );
  }

  addNode(object: GameDay) {
    return this.apollo
      .mutate({
        mutation: ADD_GAME_DAYS,
        variables: {
          input: {
            date: format(object.date, 'yyyy-MM-dd'),
            comment: object.comment ?? '',
            active: object.active ?? false,
            public: object.public ?? false,
            locked: object.locked ?? false,
            player: [],
          },
        },
      })
      .pipe(
        catchError(err => {
          this.spinner.hide();
          this.table.load();
          throw err;
        })
      );
  }

  editNode(object: GameDay) {
    return this.apollo
      .mutate({
        mutation: EDIT_GAME_DAYS,
        variables: {
          id: object.id,
          input: {
            date: format(object.date, 'yyyy-MM-dd'),
            comment: object.comment,
            active: object.active ?? false,
            public: object.public ?? false,
            locked: object.locked ?? false,
          },
        },
      })
      .pipe(
        catchError(err => {
          this.spinner.hide();
          this.table.load();
          throw err;
        })
      );
  }

  deleteNode(object: GameDay) {
    return this.apollo
      .mutate({
        mutation: DELETE_GAME_DAYS,
        variables: {
          ids: [object.id],
        },
      })
      .pipe(
        catchError(err => {
          this.spinner.hide();
          this.table.load();
          throw err;
        })
      );
  }

  loadById(
    gameDayId: number,
    showEmptyEntries: boolean
  ): Observable<WithHistory<GameDay> | null> {
    if (!gameDayId) return of(null);

    return this.apollo
      .query<{ gameDays: { nodes: WithHistory<GameDay>[] } }>({
        query: gql`
          query adminToolRentalObject($id: Int!, $excludeEmpty: Boolean!) {
            gameDays(take: 1, filter: { id: { equal: $id } }) {
              nodes {
                id
                ...gameDay
                history(excludeEmpty: $excludeEmpty) {
                  updatedUtc
                  editor {
                    ...EDITOR
                  }
                  changedProperties
                  currentValue {
                    ...gameDay
                  }
                  previousValue {
                    ...gameDay
                  }
                }
              }
            }
          }

          fragment gameDay on GameDay {
            id
            date
            comment
            active
            public
            locked
            type
            fileId
          }
          ${EDITOR_FRAGMENT}
        `,
        variables: { id: gameDayId, excludeEmpty: !showEmptyEntries },
      })
      .pipe(
        catchError(err => {
          this.spinner.hide();
          throw err;
        }),
        map(x => x.data.gameDays.nodes[0] ?? null)
      );
  }

  upsert(gameDay: GameDayEditable, file: File | null, id: number | null) {
    const files: { [key: string]: File } = {};
    if (file) files['file'] = file!;

    const input = {
      ...gameDay,
      fileReferenceName: file ? `file` : undefined,
    };

    const query = `
        mutation adminToolGameDay(${id ? updateInput : createInput}) {
            gameDay {
                ${id ? updateFragment(id) : createFragment}
            }
        }
    `;

    return this.multipart
      .query<UpsertResult>({
        query: query,
        variables: { input },
        operationName: 'adminToolGameDay',
        files: files,
      })
      .pipe(
        catchError((x?: { error: GraphQlResponse<UpsertResult> }) => {
          console.log(x);
          if (!x?.error.errors) throw x;

          x.error.errors.forEach((err: { extensions: { code: string } }) => {
            switch (err.extensions.code) {
              case 'DUPLICATE_CALL_SIGN':
                this.toastService.error('errors.player.duplicate_call_sign');
                break;
              case 'DUPLICATE_IDENTITY_ID':
                this.toastService.error('errors.player.duplicate_identity_id');
                break;
            }
          });

          return of(null);
        }),
        map(x => x?.data.gameDay.create?.id ?? x?.data.gameDay.update?.id)
      );
  }
}

export type GameDayEditable = {
  date?: string;
  comment?: string | null;
  active?: boolean;
  public?: boolean;
  locked?: boolean;
  type?: GameDayType;
  fileId?: string | null;
  // checkIn?: QueryResult<GameDayCheckin>;
  // players: QueryResult<GameDayPlayer>;
  // rental: QueryResult<GameDayPlayerRental>;
};

type UpsertResult = {
  gameDay: { update?: { id: number }; create?: { id: number } };
};

const createInput = '$input: GameDayCreateInput!';
const updateInput = '$input: GameDayEditInput!';
const createFragment = 'create(input: $input) { id }';

function updateFragment(playerId: number) {
  return `update(id: ${playerId} input: $input) { id }`;
}
