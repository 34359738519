import { Player } from 'src/app/models/entities/player';
import { RentalItem, RentalPackage } from 'src/app/models/entities/rentals';
import { GameDayCheckin } from 'src/app/models/entities/checkin';
import { QueryResult } from 'src/app/models/entities/query-result';
import { GameDayChrono } from 'src/app/models/entities/chroni';
import { Violation } from 'src/app/models/entities/violation';
import { Bb } from 'src/app/models/entities/bb';

export interface GameDay {
  id: number;
  date: string;
  comment: string | null;
  active: boolean;
  public: boolean;
  locked: boolean;
  type: GameDayType;
  checkIn: QueryResult<GameDayCheckin>;
  players: QueryResult<GameDayPlayer>;
  rental: QueryResult<GameDayPlayerRental>;
  chrono: QueryResult<GameDayChrono>;
  info: GameDayInfo;
  fileId?: string;
}

export enum GameDayType {
  Normal = 'NORMAL',
  HazardZone = 'HAZARD_ZONE',
  InviteOnly = 'INVITE_ONLY',
}

export interface GameDayCommentSubType {
  comment?: string;
  comment_CheckIn?: string;
  comment_Shop?: string;
  comment_Chrono?: string;
}

export enum AttendanceState {
  NOT_PRESENT = 'NOT_PRESENT',
  PRESENT = 'PRESENT',
  DELAYED = 'DELAYED',
  CANCELED = 'CANCELED',
}

export enum NewTagStatus {
  NEW_TAG_NEW_PLAYER = 'NEW_TAG_NEW_PLAYER',
  NEW_TAG_OLD_PLAYER = 'NEW_TAG_OLD_PLAYER',
  NEW_TAG_NEW_PLAYER_UNPAID = 'NEW_TAG_NEW_PLAYER_UNPAID',
  NEW_TAG_OLD_PLAYER_UNPAID = 'NEW_TAG_OLD_PLAYER_UNPAID',
}

export interface Squad {
  id: number;
  name: string;
  colorCodeId: number;
  coalitionId: number;
  colorHex: string;
  players: GameDayPlayer[];
  levelMedian?: number;
}

export interface GameDayPlayer {
  id: number;
  gameDayId: number;
  gameSquad?: Squad;
  comment: string;
  attendanceState: AttendanceState;
  parentalPermissionSigned: boolean;
  bbs: Bb[];
  issuedBbs: Bb[];
  player: Player;
  rentalPackages: RentalPackage[];
  rentalItems: RentalItem[];
  notPaidRentalItems: RentalItem[];
  rentals: (RentalPackage | RentalItem)[];
  issuedNewTag: boolean;
  newTagStatus?: NewTagStatus;
  violations: QueryResult<Violation>;
  violationPoints: number;
}

export interface GameDayPlayerRental extends GameDayCommentSubType {
  id: number;
  attendance: AttendanceState;
  gameSquad?: Squad;
  bbs: Bb[];
  issuedBbs: Bb[];
  player: Player;
  issuedItems: RentalItem[];
  returnedItems: RentalItem[];
  rentalPackages: RentalPackage[];
  rentalItems: RentalItem[];
  notPaidRentalItems: RentalItem[];
  paidRentalItems: RentalItem[];
  rentals: (RentalPackage | RentalItem)[];
  itemsToIssue: number;
  rentalsCount: number;
}

export interface GameDayInfo {
  playerCount: number;
  presentPlayers: number;
  newPlayers: number;
  bBCounts: GameDayInfoBBCount[];
  primaryCount: number;
  backupCount: number;
  packageCount: number;
  squads: { squad: { name: string; colorHex: string }; playerCount: number }[];
}

export interface GameDayInfoBBCount {
  bBs: number;
  count: number;
}
