// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  auth: {
    authority: 'https://dev.identity.area49.app',
    redirectUrl: 'https://dev.admin.area49.app',
    postLogoutRedirectUri: 'https://dev.admin.area49.app/',
  },
  api: {
    url: 'https://dev.api.area49.app',
  },
  external: {
    cloud: 'https://cloud.area49.de',
    easyRedirect: 'https://dev.r.area49.app',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
