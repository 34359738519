<ng-container *ngIf="column.type === TableColumnType.CUSTOM">
  <ng-container
    *ngTemplateOutlet="
      customColumnTemplate;
      context: { $implicit: value, column: column }
    "></ng-container>
</ng-container>

<ng-container *ngIf="column.type === TableColumnType.IMAGE">
  <img
    *ngIf="!!value[column.name]"
    [src]="environment.api.url + '/api/images/' + value[column.name]"
    class="table-image"
    [alt]="column.name" />
</ng-container>

<ng-container *ngIf="column.type === TableColumnType.EDITOR">
  <app-column-content [inlineEdit]="inlineEdit">
    <ng-template columnContentInput>
      <span *ngIf="value?.__typename">
        {{ value.__typename === 'User' ? value.username : value.client }}
      </span>
    </ng-template>
    <ng-template columnContentOutput>
      <span *ngIf="value?.__typename">
        {{ value.__typename === 'User' ? value.username : value.client }}
      </span>
    </ng-template>
  </app-column-content>
</ng-container>

<ng-container *ngIf="column.type === TableColumnType.ID">
  <app-column-content [inlineEdit]="inlineEdit">
    <ng-template columnContentInput>
      <input
        [name]="rowIndex + '_' + column.name"
        class="table-edit-input"
        pInputText
        type="text"
        [(ngModel)]="value[column.name]"
        [disabled]="!column.editable || isEditingNew"
        [required]="column.required ?? false" />
    </ng-template>
    <ng-template columnContentOutput>
      {{ value[column.name] }}
    </ng-template>
  </app-column-content>
</ng-container>

<ng-container *ngIf="column.type === TableColumnType.STRING">
  <app-column-content [inlineEdit]="inlineEdit">
    <ng-template columnContentInput>
      <input
        [name]="rowIndex + '_' + column.name"
        class="table-edit-input"
        pInputText
        type="text"
        [(ngModel)]="value[column.name]"
        [disabled]="!column.editable"
        [required]="column.required ?? false" />
    </ng-template>
    <ng-template columnContentOutput>
      <span *ngIf="column.valuePipe">{{ column.valuePipe(value) }}</span>
      <ng-container *ngIf="!column.valuePipe">
        {{ value[column.name] }}
      </ng-container>
    </ng-template>
  </app-column-content>
</ng-container>

<ng-container *ngIf="column.type === TableColumnType.PASSWORD">
  <app-column-content [inlineEdit]="inlineEdit">
    <ng-template columnContentInput>
      <div class="flex w-full justify-center items-center">
        <div
          *ngIf="!column.passwordOptions?.visible"
          class="flex w-full justify-center items-center">
          <input
            [name]="rowIndex + '_' + column.name"
            class="table-edit-input"
            pInputText
            type="text"
            [ngModel]="'**************'"
            [disabled]="!column.editable"
            [required]="column.required ?? false" />
          <p-button
            class="btn icon-btn"
            icon="pi pi-eye-slash"
            (onClick)="setPasswordColumnVisibility(column, true)"></p-button>
        </div>

        <div
          *ngIf="column.passwordOptions?.visible"
          class="flex w-full justify-center items-center">
          <input
            [name]="rowIndex + '_' + column.name"
            class="table-edit-input"
            pInputText
            type="text"
            [(ngModel)]="value[column.name]"
            [disabled]="!column.editable"
            [required]="column.required ?? false" />
          <p-button
            class="btn icon-btn"
            icon="pi pi-eye"
            (onClick)="setPasswordColumnVisibility(column, false)"></p-button>
        </div>
        <p-button
          class="btn icon-btn"
          icon="pi pi-copy"
          (onClick)="copy(value[column.name])"></p-button>
      </div>
    </ng-template>
    <ng-template columnContentOutput>
      <div class="flex w-full justify-center items-center">
        <div
          *ngIf="!column.passwordOptions?.visible"
          class="flex w-full justify-center items-center">
          <input
            [name]="rowIndex + '_' + column.name"
            class="table-edit-input"
            pInputText
            type="text"
            [ngModel]="'**************'"
            disabled />
          <p-button
            class="btn icon-btn"
            icon="pi pi-eye-slash"
            (onClick)="setPasswordColumnVisibility(column, true)"></p-button>
        </div>

        <div
          *ngIf="column.passwordOptions?.visible"
          class="flex w-full justify-center items-center">
          <input
            [name]="rowIndex + '_' + column.name"
            class="table-edit-input"
            pInputText
            type="text"
            [(ngModel)]="value[column.name]"
            disabled />
          <p-button
            class="btn icon-btn"
            icon="pi pi-eye"
            (onClick)="setPasswordColumnVisibility(column, false)"></p-button>
        </div>
        <p-button
          class="btn icon-btn"
          icon="pi pi-copy"
          (onClick)="copy(value[column.name])"></p-button>
      </div>
    </ng-template>
  </app-column-content>
</ng-container>

<ng-container *ngIf="column.type === TableColumnType.NUMBER">
  <app-column-content [inlineEdit]="inlineEdit">
    <ng-template columnContentInput>
      <input
        [name]="rowIndex + '_' + column.name"
        class="table-edit-input"
        pInputText
        type="number"
        min="0"
        [(ngModel)]="value[column.name]"
        [disabled]="!column.editable"
        [required]="column.required ?? false" />
    </ng-template>
    <ng-template columnContentOutput>
      <span *ngIf="column.valuePipe"
        >{{ column.valuePipe(value)
        }}<span *ngIf="column.currency">{{ column.currency }}</span></span
      >
      <span *ngIf="!column.valuePipe"
        >{{ value[column.name] }}
        <span *ngIf="column.currency">{{ column.currency }}</span></span
      >
    </ng-template>
  </app-column-content>
</ng-container>

<ng-container *ngIf="column.type === TableColumnType.BOOLEAN">
  <app-column-content [inlineEdit]="inlineEdit">
    <ng-template columnContentInput>
      <p-triStateCheckbox
        [name]="rowIndex + '_' + column.name"
        [(ngModel)]="value[column.name]"
        [disabled]="!column.editable"
        [required]="column.required ?? false">
      </p-triStateCheckbox>
    </ng-template>
    <ng-template columnContentOutput>
      <p-triStateCheckbox
        [name]="rowIndex + '_' + column.name"
        [disabled]="true"
        [(ngModel)]="value[column.name]">
      </p-triStateCheckbox>
    </ng-template>
  </app-column-content>
</ng-container>

<ng-container *ngIf="column.type === TableColumnType.DATE">
  <app-column-content [inlineEdit]="inlineEdit">
    <ng-template columnContentInput>
      <p-calendar
        [name]="rowIndex + '_' + column.name"
        class="table-edit-input"
        dateFormat="yy-mm-dd"
        [showIcon]="true"
        [(ngModel)]="value[column.name]"
        [disabled]="!column.editable"
        [required]="column.required ?? false"></p-calendar>
    </ng-template>
    <ng-template columnContentOutput>
      {{ value[column.name] }}
    </ng-template>
  </app-column-content>
</ng-container>

<ng-container *ngIf="column.type === TableColumnType.PLAYER_ID">
  <app-player-id-column
    *ngIf="value; else defaultPlayer"
    [player]="value"
    [open]="column.openPlayer"></app-player-id-column>
  <ng-template #defaultPlayer>
    <span [style]="'color: ' + value?.colorHex + '; font-weight: bold;'">{{
      value[column.name]
    }}</span>
  </ng-template>
</ng-container>

<ng-container *ngIf="column.type === TableColumnType.PLAYER_CALL_SIGN">
  <app-column-content [inlineEdit]="inlineEdit">
    <ng-template columnContentInput>
      <input
        [name]="rowIndex + '_' + column.name"
        class="table-edit-input"
        pInputText
        type="text"
        [(ngModel)]="value.callSign"
        [disabled]="!column.editable"
        [required]="column.required ?? false" />
    </ng-template>
    <ng-template columnContentOutput>
      <span *ngIf="value.team">[{{ value.team.abbreviation }}]</span>
      {{ value.callSign }}
    </ng-template>
  </app-column-content>
</ng-container>

<ng-container *ngIf="column.type === TableColumnType.COMMENT">
  <app-column-content [inlineEdit]="inlineEdit">
    <ng-template columnContentInput>
      <div class="flex gap-5">
        <input
          [name]="rowIndex + '_' + column.name"
          class="table-edit-input"
          pInputText
          type="text"
          [(ngModel)]="value[column.name]"
          [disabled]="!column.editable"
          [required]="column.required ?? false" />
        <p-dropdown
          styleClass="h-full"
          class="flex-1"
          *ngIf="column.commentPresets"
          [name]="column.name + 'preset'"
          [(ngModel)]="value[column.name]"
          optionLabel="id"
          [options]="column.commentPresets"
          [showClear]="true">
          <ng-template let-value pTemplate="item">
            {{ value }}
          </ng-template>
        </p-dropdown>
      </div>
    </ng-template>
    <ng-template columnContentOutput>
      <span
        *ngIf="value[column.name] && value[column.name] !== ''; else noComment"
        >{{ value[column.name] }}</span
      >
      <ng-template #noComment>...</ng-template>
    </ng-template>
  </app-column-content>
</ng-container>

<ng-container *ngIf="column.type === TableColumnType.DROPDOWN">
  <app-column-content [inlineEdit]="inlineEdit">
    <ng-template columnContentInput>
      <span *ngIf="!column.dropdownOptions; else dropdown"
        >Options not set</span
      >
      <ng-template #dropdown>
        <p-dropdown
          [name]="rowIndex + '_' + column.name"
          [options]="dropdownOptions[column.name] | async"
          [(ngModel)]="value[column.name]"
          [showClear]="column.dropdownOptions?.clearable"
          [dataKey]="column.dropdownOptions?.dataKey"
          [optionLabel]="column.dropdownOptions?.optionLabel"
          [optionValue]="column.dropdownOptions?.optionValue"
          [placeholder]="column.dropdownOptions?.placeholder"
          [filter]="column.dropdownOptions?.filterable"
          [filterBy]="column.dropdownOptions?.filterBy"
          [disabled]="!column.editable"
          [required]="column.required ?? false"
          emptyMessage="{{ 'common.no_entries_found' | translate }}"
          (onShow)="openDropdown()">
        </p-dropdown>
      </ng-template>
    </ng-template>
    <ng-template columnContentOutput>
      <span *ngIf="column.valuePipe">{{ column.valuePipe(value) }}</span>
      <span
        *ngIf="
          !column.valuePipe && value[column.name] && value[column.name] !== ''
        "
        >{{ value[column.name] }}</span
      >
    </ng-template>
  </app-column-content>
</ng-container>

<ng-container *ngIf="column.type === TableColumnType.CHIPSELECT">
  <app-column-content [inlineEdit]="inlineEdit">
    <ng-template columnContentInput>
      <app-chip-select
        *ngIf="column.chipSelectOptions"
        [name]="rowIndex + '_' + column.name"
        [items]="chipSelectOptions[column.name] | async"
        [itemValueGetter]="column.chipSelectOptions.itemValueGetter"
        [quickSelectItems]="chipSelectFavOptions[column.name] | async"
        [(selectedItems)]="value[column.name]"
        [addDialogHeaderTKey]="
          column.chipSelectOptions.addDialogHeaderTKey ?? ''
        "
        [addDropdownPlaceholderTKey]="
          column.chipSelectOptions.addDropdownPlaceholderTKey ?? ''
        "
        [disabled]="!column.editable"
        (addButtonClicked)="openChipSelect(value)"></app-chip-select>
    </ng-template>
    <ng-template columnContentOutput>
      <app-chip-select
        *ngIf="column.chipSelectOptions"
        [name]="rowIndex + '_' + column.name"
        [items]="chipSelectOptions[column.name] | async"
        [itemValueGetter]="column.chipSelectOptions.itemValueGetter"
        [quickSelectItems]="chipSelectFavOptions[column.name] | async"
        [(selectedItems)]="value[column.name]"
        [addDialogHeaderTKey]="
          column.chipSelectOptions.addDialogHeaderTKey ?? ''
        "
        [addDropdownPlaceholderTKey]="
          column.chipSelectOptions.addDropdownPlaceholderTKey ?? ''
        "
        [disabled]="true"
        (addButtonClicked)="openChipSelect(value)"></app-chip-select>
    </ng-template>
  </app-column-content>
</ng-container>

<ng-container *ngIf="column.type === TableColumnType.TEAM">
  <app-column-content [inlineEdit]="inlineEdit">
    <ng-template columnContentInput>
      <p-dropdown
        *ngIf="value"
        [name]="rowIndex + '_' + column.name"
        [options]="dropdownOptions[column.name] | async"
        [(ngModel)]="value[column.name]"
        [showClear]="column.dropdownOptions?.clearable"
        dataKey="id"
        optionLabel="name"
        placeholder="{{ 'common.team' | translate }}"
        [filter]="true"
        filterBy="name"
        [disabled]="!column.editable"
        [required]="column.required ?? false"
        (onShow)="openDropdown()">
      </p-dropdown>
    </ng-template>
    <ng-template columnContentOutput>
      <span *ngIf="column.valuePipe">{{ column.valuePipe(value)?.name }}</span>
      <span *ngIf="!column.valuePipe">
        {{ value?.name }}
      </span>
    </ng-template>
  </app-column-content>
</ng-container>

<ng-container *ngIf="column.type === TableColumnType.ATTENDANCE">
  <app-column-content [inlineEdit]="inlineEdit">
    <ng-template columnContentInput>
      <p-dropdown
        [name]="rowIndex + '_' + column.name"
        [options]="attendanceStates"
        [(ngModel)]="value[column.name]"
        [showClear]="column.dropdownOptions?.clearable"
        dataKey="value"
        optionLabel="label"
        optionValue="value"
        placeholder="{{ 'common.attendance' | translate }}"
        [disabled]="!column.editable"
        [required]="column.required ?? false">
      </p-dropdown>
    </ng-template>
    <ng-template columnContentOutput>
      {{ 'attendance_state.' + value[column.name] | translate }}
    </ng-template>
  </app-column-content>
</ng-container>

<ng-container *ngIf="column.type === TableColumnType.RATING">
  <app-column-content [inlineEdit]="inlineEdit">
    <ng-template columnContentInput>
      <p-rating
        [name]="rowIndex + '_' + column.name"
        [(ngModel)]="value[column.name]"
        [stars]="column.ratingOptions?.stars ?? 5"
        [cancel]="column.ratingOptions?.cancel ?? false"
        [disabled]="!column.editable"
        [required]="column.required ?? false" />
    </ng-template>
    <ng-template columnContentOutput>
      <p-rating
        [name]="rowIndex + '_' + column.name"
        [(ngModel)]="value[column.name]"
        [stars]="column.ratingOptions?.stars ?? 5"
        [cancel]="column.ratingOptions?.cancel ?? false"
        [disabled]="true"
        [required]="column.required ?? false" />
    </ng-template>
  </app-column-content>
</ng-container>
